import IsibuteDocument from "./IsibuteDocument";

class DocumentManager {
  constructor() {
    this.history = [new IsibuteDocument({ document: { documentComponents: {} } })];
    this.historyIndex = 0;
  }

  getCurrentDocument = () => {
    return this.history[this.historyIndex];
  };

  clearStaleHistory = () => {
    this.history = this.history.slice(0, this.historyIndex + 1);
  };

  addDocumentToHistoryFromData = (data) => {
    this.clearStaleHistory();
    this.history.push(new IsibuteDocument(data));
    this.historyIndex += 1;
  };

  setCursorOnCurrentDocument = ({
    cursorStartPosition,
    cursorEndPosition,
    documentStartComponentCursorPosition,
    documentEndComponentCursorPosition,
    documentStartComponentNode,
    documentEndComponentNode,
  }) => {
    this.clearStaleHistory();

    const nextDocument = this.getCurrentDocument().deepCopy();

    nextDocument.cursorStartPosition = cursorStartPosition;
    nextDocument.cursorEndPosition = cursorEndPosition;
    nextDocument.currentStartNodeCursorPosition = documentStartComponentCursorPosition;
    nextDocument.currentEndNodeCursorPosition = documentEndComponentCursorPosition;

    const documentStartComponentNodeKeyPath = documentStartComponentNode.id.split("-");
    nextDocument.currentStartNode = nextDocument.getDocumentComponentByKeyPath({
      keyPath: documentStartComponentNodeKeyPath
    });

    const documentEndComponentNodeKeyPath = documentEndComponentNode.id.split("-");
    nextDocument.currentEndNode = nextDocument.getDocumentComponentByKeyPath({
      keyPath: documentEndComponentNodeKeyPath
    });

    this.history.push(nextDocument);
    this.historyIndex += 1;
  }

  processEvent = ({
    key,
    altKeyIsPressed,
    ctrlKeyIsPressed,
    metaKeyIsPressed,
    shiftKeyIsPressed,
  }) => {
    // All Are Pressed

    if (altKeyIsPressed && ctrlKeyIsPressed && metaKeyIsPressed && shiftKeyIsPressed) {
      // TODO
    }

    // Three Are Pressed

    if (!altKeyIsPressed && ctrlKeyIsPressed && metaKeyIsPressed && shiftKeyIsPressed) {
      // TODO
    }

    if (altKeyIsPressed && !ctrlKeyIsPressed && metaKeyIsPressed && shiftKeyIsPressed) {
      // TODO
    }

    if (altKeyIsPressed && ctrlKeyIsPressed && !metaKeyIsPressed && shiftKeyIsPressed) {
      // TODO
    }

    if (altKeyIsPressed && ctrlKeyIsPressed && metaKeyIsPressed && !shiftKeyIsPressed) {
      // TODO
    }

    // Two are pressed

    if (!altKeyIsPressed && !ctrlKeyIsPressed && metaKeyIsPressed && shiftKeyIsPressed) {
      this.processEventWhenMetaAndShiftKeysArePressed({ key });
    }

    if (!altKeyIsPressed && ctrlKeyIsPressed && !metaKeyIsPressed && shiftKeyIsPressed) {
      // TODO
    }

    if (!altKeyIsPressed && ctrlKeyIsPressed && metaKeyIsPressed && !shiftKeyIsPressed) {
      // TODO
    }

    if (altKeyIsPressed && !ctrlKeyIsPressed && !metaKeyIsPressed && shiftKeyIsPressed) {
      // TODO
    }

    if (altKeyIsPressed && !ctrlKeyIsPressed && metaKeyIsPressed && !shiftKeyIsPressed) {
      // TODO
    }

    if (altKeyIsPressed && ctrlKeyIsPressed && !metaKeyIsPressed && !shiftKeyIsPressed) {
      // TODO
    }

    // One is pressed

    if (!altKeyIsPressed && !ctrlKeyIsPressed && !metaKeyIsPressed && shiftKeyIsPressed) {
      // TODO
    }

    if (!altKeyIsPressed && !ctrlKeyIsPressed && metaKeyIsPressed && !shiftKeyIsPressed) {
      this.processEventWhenMetaKeyIsPressed({ key });
    }

    if (!altKeyIsPressed && ctrlKeyIsPressed && !metaKeyIsPressed && !shiftKeyIsPressed) {
      // TODO
    }

    if (altKeyIsPressed && !ctrlKeyIsPressed && !metaKeyIsPressed && !shiftKeyIsPressed) {
      // TODO
    }

    // None are pressed

    if (!altKeyIsPressed && !ctrlKeyIsPressed && !metaKeyIsPressed && !shiftKeyIsPressed) {
      this.processEventWhenNoSpecialKeysArePressed({ key });
    }
  };

  processEventWhenMetaAndShiftKeysArePressed = ({ key }) => {
    switch (key) {
      case 'Alt':
        break;
      case 'ArrowDown':
        break;
      case 'ArrowLeft':
        break;
      case 'ArrowRight':
        break;
      case 'ArrowUp':
        break;
      case 'Backspace':
        break;
      case 'Control':
        break;
      case 'Enter':
        break;
      case 'Meta':
        break;
      case 'Shift':
        break;
      default:
        if (key == 'z') {
          if (this.historyIndex < this.history.length - 1) {
            this.historyIndex += 1;
          }
        }
    }
  };

  processEventWhenMetaKeyIsPressed = ({ key }) => {
    switch (key) {
      case 'Alt':
        break;
      case 'ArrowDown':
        break;
      case 'ArrowLeft':
        break;
      case 'ArrowRight':
        break;
      case 'ArrowUp':
        break;
      case 'Backspace':
        break;
      case 'Control':
        break;
      case 'Enter':
        break;
      case 'Meta':
        break;
      case 'Shift':
        break;
      default:
        if (key == 'z') {
          if (this.historyIndex > 0) {
            this.historyIndex -= 1;
          }
        }
    }
  };

  processEventWhenNoSpecialKeysArePressed = ({ key }) => {
    let nextDocument;

    switch (key) {
      case 'Alt':
        break;
      case 'ArrowDown':
        break;
      case 'ArrowLeft':
        this.clearStaleHistory();

        nextDocument = this.getCurrentDocument().deepCopy();
        nextDocument.arrowLeft();
        this.history.push(nextDocument);
        this.historyIndex += 1;
  
        break;
      case 'ArrowRight':
        this.clearStaleHistory();

        nextDocument = this.getCurrentDocument().deepCopy();
        nextDocument.arrowRight();
        this.history.push(nextDocument);
        this.historyIndex += 1;
  
        break;
      case 'ArrowUp':
        break;
      case 'Backspace':
        this.clearStaleHistory();

        nextDocument = this.getCurrentDocument().deepCopy();
        nextDocument.backspace();
        this.history.push(nextDocument);
        this.historyIndex += 1;
  
        break;
      case 'Control':
        break;
      case 'Enter':
        this.clearStaleHistory();

        nextDocument = this.getCurrentDocument().deepCopy();
        nextDocument.enter();
        this.history.push(nextDocument);
        this.historyIndex += 1;
  
        break;
      case 'Meta':
        break;
      case 'Shift':
        break;
      default:
        this.clearStaleHistory();

        nextDocument = this.getCurrentDocument().deepCopy();
        nextDocument.addCharacter(key);
        this.history.push(nextDocument);
        this.historyIndex += 1;
    }
  };

  applyFormatToSelectedRange = (format) => {
    this.clearStaleHistory();

    const nextDocument = this.getCurrentDocument().deepCopy();
    nextDocument.applyFormat(format);
    this.history.push(nextDocument);
    this.historyIndex += 1;
  };

  applyStyleToSelectedRange = (style) => {
    this.clearStaleHistory();

    const nextDocument = this.getCurrentDocument().deepCopy();
    nextDocument.applyStyle(style);
    this.history.push(nextDocument);
    this.historyIndex += 1;
  };
};

export default DocumentManager;
