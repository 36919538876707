import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';

import {
  handleSelectionChange,
  updateFromKeydown,
  applyFormatToSelectedRange,
  applyStyleToSelectedRange,
} from './RichTextEditerHelpers';

import styles from './Discourse.module.scss';

const DiscourseMainSection = ({
  authenticatedUser,
  setState,
  state
}) => {
  const [contentEditable, setContentEditable] = useState(false);

  const parse = (documentComponent, documentComponentId) => {
    const htmlTextFromDocumentComponent = (documentComponent) => {
      return documentComponent.styling.map((style, index) => {
        const stylingPrefix = style.types.map((type) => {
          switch (type) {
            case 'i':
              return `<i id=\"${documentComponentId}-${index}\">`;
            case 'b':
              return `<b id=\"${documentComponentId}-${index}\">`;
            case 'sup':
              return `<sup id=\"${documentComponentId}-${index}\">`;
            case 'a':
              return `<a id=\"${documentComponentId}-${index}\" href=\"${style.href}\">`;
            default:
              return `<span id=\"${documentComponentId}-${index}\">`;
          }
        }).join("");

        const textContent = documentComponent.unstyledContent.substring(style.start, style.end + 1);

        const stylingPostfix = style.types.map((type) => {
          switch (type) {
            case 'i':
              return '</i>';
            case 'b':
              return '</b>';
            case 'sup':
              return '</sup>';
            case 'a':
              return '</a>';
            default:
              return '</span>';
          }
        }).join("");

        return stylingPrefix + textContent + stylingPostfix;
      }).join("");
    };

    const htmlText = htmlTextFromDocumentComponent(documentComponent);

    return ReactHtmlParser(htmlText, {
      transform: (node) => {
        if (node.type === 'tag' && node.name === 'a') {
          const finalHref = node?.attribs?.href?.includes('http') ? node?.attribs?.href : `//${node?.attribs?.href}`;
          return <a href={finalHref} target="_blank">{node.children[0].data}</a>;
        }
      }
    });
  };

  const htmlForDocumentComponent = (documentComponent) => {
    switch(documentComponent.documentComponentType) {
      case 'DocumentTitle':
        return <h1 id={documentComponent.id} class={styles.title}>{parse(documentComponent, documentComponent.id)}</h1>;
      case 'DocumentSubtitle':
        return <p id={documentComponent.id} class={styles.subtitle}>{parse(documentComponent, documentComponent.id)}</p>;
      case 'DocumentSectionTitle':
        if (documentComponent.size == 2) {
          return <h2 id={documentComponent.id} class={styles.sectionTitle} key={documentComponent.id}>{parse(documentComponent, documentComponent.id)}</h2>;
        }

        return <h3 id={documentComponent.id} class={styles.sectionTitle} key={documentComponent.id}>{parse(documentComponent, documentComponent.id)}</h3>;
      case 'DocumentImage':
        const mainImageSectionVariant = {
          'large': styles.mainImageSection,
          'medium': styles.mainImageSection,
          'small': styles.mainImageSectionSmall,
        }[documentComponent?.displaySizeVariant] || styles.mainImageSection;

        return (
          <div id={mainImageSectionVariant}>
            <img id={documentComponent.id} class={styles.mainImage} src={documentComponent?.imagePath} />
            <p id={styles.mainImageCredit}>{documentComponent?.caption}</p>
          </div>
        );
      case 'DocumentParagraph':
        return <p id={documentComponent.id} class={styles.articleParagraph} key={documentComponent.id}>{parse(documentComponent, documentComponent.id)}</p>;
      case 'DocumentEmphasis':
        return <p id={documentComponent.id} class={styles.emphasize} key={documentComponent.id}>{parse(documentComponent, documentComponent.id)}</p>;
      case 'DocumentCitationsSection':
        const citations = Object.values(documentComponent.documentCitations)
          .sort((thisComponent, thatComponent) => {
            return thisComponent.ordinal - thatComponent.ordinal;
          })
          ?.map((citation) => {
            const fullCitationId = `${documentComponent.id}-${citation.id}`;
            return <li id={fullCitationId} class={styles.citation} key={citation.id}>{parse(citation, fullCitationId)}</li>;
          });

        return <ol id={documentComponent.id}>{citations}</ol>;
      default:
        return null;
    }
  };

  const sortedDocumentComponents = Object.values(state?.documentManager?.getCurrentDocument()?.documentComponents).sort((thisComponent, thatComponent) => {
    return thisComponent.ordinal - thatComponent.ordinal;
  });

  const showEditButton = authenticatedUser?.canEditAllDocuments;
  const editButtonText = contentEditable ? 'Save' : 'Edit';

  return (
    <>
      {showEditButton && (
        <div className={styles.editBtnContainer}>
          <button
            className={styles.editBtn}
            onClick={() => setContentEditable(prevContentEditable => {
              return !prevContentEditable;
            })}
          >
            {editButtonText}
          </button>
        </div>
      )}
      {contentEditable && (
        <div className={styles.stylingTool}>
          <button
            className={styles.stylingBtn}
            onClick={
              () => {
                applyStyleToSelectedRange({
                  style: { type: 'b' },
                  setState: setState
                })
              }
            }
          >
            B
          </button>
          <button
            className={styles.stylingBtn}
            onClick={
              () => {
                applyStyleToSelectedRange({
                  style: { type: 'i' },
                  setState: setState
                })
              }
            }
          >
            I
          </button>
          <button
            className={styles.stylingBtn}
            onClick={
              () => {
                applyStyleToSelectedRange({
                  style: { type: 'a', href: null },
                  setState: setState
                })
              }
            }
          >
            L
          </button>
          <button
            className={styles.stylingBtn}
            onClick={
              () => {
                applyFormatToSelectedRange({
                  format: { type: 'DocumentTitle' },
                  setState: setState
                })
              }
            }
          >
            T
          </button>
          <button
            className={styles.stylingBtn}
            onClick={
              () => {
                applyFormatToSelectedRange({
                  format: { type: 'DocumentSubtitle' },
                  setState: setState
                })
              }
            }
          >
            ST
          </button>
          <button
            className={styles.stylingBtn}
            onClick={
              () => {
                applyFormatToSelectedRange({
                  format: { type: 'DocumentSectionTitle', size: 2 },
                  setState: setState
                })
              }
            }
          >
            BH
          </button>
          <button
            className={styles.stylingBtn}
            onClick={
              () => {
                applyFormatToSelectedRange({
                  format: { type: 'DocumentSectionTitle', size: 3 },
                  setState: setState
                })
              }
            }
          >
            SH
          </button>
          <button
            className={styles.stylingBtn}
            onClick={
              () => {
                applyFormatToSelectedRange({
                  format: { type: 'DocumentEmphasis' },
                  setState: setState
                })
              }
            }
          >
            E
          </button>
          <button
            className={styles.stylingBtn}
            onClick={
              () => {
                applyStyleToSelectedRange({
                  style: { type: 'sup' },
                  setState: setState
                })
              }
            }
          >
            S
          </button>
          <button
            className={styles.stylingBtn}
            onClick={
              () => {
                applyFormatToSelectedRange({
                  format: { type: 'DocumentCitationsSection' },
                  setState: setState
                })
              }
            }
          >
            OL
          </button>
        </div>
      )}
      <div
        id={styles.mainSection}
        contentEditable={contentEditable}
        onKeyDown={
          (event) => {
            updateFromKeydown({ event: event, setState: setState });
          }
        }
        onClick={() => handleSelectionChange({ editableContentId: styles.mainSection, setState: setState })}
      >
        {sortedDocumentComponents.map((documentComponent) => {
          return htmlForDocumentComponent(documentComponent);
        })}
      </div>
    </>
  );
};

export default DiscourseMainSection;
